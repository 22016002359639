<template>
  <modal-window
    v-if="modal_open"
    :is-closable="!loading"
    :modal_open="showModal"
    :max-width="550"
    title="Make an enquiry and save your visualisation"
    @close="$emit('close')"
  >
    <loading :loading="loading">
      <div class="">
        <div>Please complete the simple form below.</div>
        <div class="mt-3">
          <div class="flex -mx-2">
            <div class="mb-3 mx-2 flex-1">
              <div class="">
                <input
                  v-model="firstName"
                  :readonly="demo_mode"
                  class="form-control"
                  placeholder="First Name"
                />
              </div>
            </div>
            <div class="mb-3 mx-2 flex-1">
              <div class="">
                <input
                  v-model="lastName"
                  :readonly="demo_mode"
                  class="form-control"
                  placeholder="Last Name"
                />
              </div>
            </div>
          </div>

          <div class="flex flex-col -mx-2">
            <div class="flex mb-3 px-2 w-full">
              <div class="flex-grow">
                <input
                  v-model="email"
                  :readonly="demo_mode"
                  type="email"
                  class="form-control"
                  placeholder="Email"
                />
              </div>
            </div>
            <div class="flex mb-3 px-2 w-full">
              <div class="flex-grow">
                <input
                  v-model="phone"
                  :readonly="demo_mode"
                  class="form-control"
                  placeholder="Phone"
                />
              </div>
            </div>
          </div>
          <AddressForm :is-primary-toggle="false" @address-updated="addressUpdated" />
          <div>
            <textarea
              v-model="notes"
              :readonly="demo_mode"
              placeholder="Enter any notes here"
              maxlength="255"
              rows="3"
              class="form-control"
            ></textarea>
            <p v-if="notes.length > 0">{{ notes.length }} / 255</p>
          </div>
        </div>
        <div class="flex mt-3">
          <div>
            <input v-model="acceptTerms" type="checkbox" />
          </div>
          <div class="ml-4 text-xs text-gray-600">
            Tick the box to confirm that you agree for the information you have provided to be
            passed to us, we will contact you to discuss your items.
            <template v-if="privacyPolicyDocumentUrl != ''">
              <a
                class="text-green-600 hover:text-green-800 cursor-pointer"
                :href="privacyPolicyDocumentUrl"
                target="_blank"
              >
                Click here</a>
              for more information about how we will use your personal information.
            </template>
            <template v-if="termsDocumentUrl != ''">
              <a
                class="text-green-600 hover:text-green-800 cursor-pointer"
                :href="termsDocumentUrl"
                target="_blank"
              >
                Click here</a>
              to view our terms and conditions.
            </template>
          </div>
        </div>
        <div class="mt-3">
          <button class="btn-action btn-lg w-full" @click="submitEnquiry()">Submit Enquiry</button>
        </div>
        <div>
          <div class="mb-4 mt-10">
            <strong class="text-xl"> What happens next? </strong>
            <i class="fal fa-envelope"></i>
          </div>
          <div class="mb-3">
            You will receive an instant email containing a summary of your enquiry and visualisation
            of your home.
          </div>
          <div v-if="isDealerNetworkMode">One of our trusted Fabricators will be in touch with you shortly.</div>
          <div v-else>A member of our team will contact you shortly.</div>
        </div>
      </div>
    </loading>
  </modal-window>
</template>

<script>
import validate from 'validate.js';
import { mapState, mapActions, mapGetters } from 'vuex';
import AddressForm from '@/components/shared/address/AddressForm.vue';

export default {
  components: {
    AddressForm,
  },
  props: ['showModal'],
  data() {
    return {
      vendor_version: true,
      firstName: '',
      lastName: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      addressTown: '',
      addressCounty: '',
      addressCode: '',
      addressIso: 'GB',
      latitude: '',
      longitude: '',
      email: '',
      phone: '',
      notes: '',
      acceptTerms: false,
      termsDocumentUrl: 'http://undefined',
      privacyPolicyDocumentUrl: 'http://undefined',
      loading: false,
      manualAddressEntry: false,
      address_picked: false,
      modal_open: false,
    };
  },
  computed: {
    ...mapGetters({
      brandedThanksURL: 'branded/thanksURL',
    }),
    ...mapState('auth', {
      isDealerNetworkMode: 'isDealerNetworkMode',
    }),
    formatted_address() {
      return [
        this.addressLine1,
        this.addressLine2,
        this.addressLine3,
        this.addressTown,
        this.addressCounty,
        this.addressCode,
      ]
        .filter((val) => val)
        .join(', ');
    },
  },
  watch: {
    showModal(show) {
      if (show) {
        this.$store.commit('visualiser/deactivateAllImages');
        this.loadTerms();
      }
    },
    addressIso(val) {
      if (val !== 'GB') {
        this.manualAddressEntry = true;
      }
    },
  },
  async mounted() {
    if (this.showModal) {
      await this.loadTerms();
    }

    if (this.demo_mode) {
      this.firstName = window.helpers.fake_firstname();
      this.lastName = window.helpers.fake_lastname();

      this.phone = window.helpers.fake_phonenumber();

      this.email = 'demo@bm-touch.co.uk';

      this.notes = window.helpers.fake_notes();

      this.pickedAddress({
        address1: window.helpers.fake_address1(),
        address2: window.helpers.fake_address2(),
        address3: '',
        town: window.helpers.fake_town(),
        county: window.helpers.fake_county(),
        postcode: window.helpers.fake_postcode(),
      });
    }
    this.modal_open = true;
  },
  methods: {
    ...mapActions({
      getDocsToAccept: 'touch/getDocsToAccept',
    }),
    async loadTerms() {
      const documents = await this.getDocsToAccept();
      const terms = documents.filter((document) => document.type === 1)[0];
      const privacyPolicy = documents.filter((document) => document.type === 2)[0];

      if (terms) {
        this.termsDocumentUrl = terms.url;
        if (!this.termsDocumentUrl.includes('//')) {
          this.termsDocumentUrl = `http://${this.termsDocumentUrl}`;
        }
      }

      if (privacyPolicy) {
        this.privacyPolicyDocumentUrl = privacyPolicy.url;
        if (!this.privacyPolicyDocumentUrl.includes('//')) {
          this.privacyPolicyDocumentUrl = `http://${this.privacyPolicyDocumentUrl}`;
        }
      }
    },
    addressUpdated(newAddress) {
      this.addressLine1 = newAddress.addressLine1;
      this.addressLine2 = newAddress.addressLine2;
      this.addressLine3 = newAddress.addressLine3;
      this.addressTown = newAddress.addressTown;
      this.addressCounty = newAddress.addressCounty;
      this.addressCode = newAddress.addressCode;
      this.addressIso = newAddress.addressIso;
      this.latitude = newAddress.latitude;
      this.longitude = newAddress.longitude;
    },
    async saveQuote({
      firstName,
      lastName,
      email,
      phone,
      addressLine1,
      addressLine2,
      addressLine3,
      addressTown,
      addressCode,
      addressIso,
      notes,
      latitude,
      longitude,
    }) {
      this.saving_quote = true;
      this.loading = true;
      await this.$store.dispatch('basket/saveBrandedEnquiry', {
        firstName,
        lastName,
        email,
        phone,
        addressLine1,
        addressLine2,
        addressLine3,
        addressTown,
        addressCode,
        addressIso,
        notes,
        latitude,
        longitude,
      });
      this.loading = false;
      this.routerPush(this.brandedThanksURL);
    },
    pickedAddress(address) {
      this.addressLine1 = address.address1;
      this.addressLine2 = address.address2;
      this.addressLine3 = address.address3;
      this.addressTown = address.town;
      this.addressCounty = address.county;
      this.addressCode = address.postcode;
      this.latitude = address.latitude;
      this.longitude = address.longitude;
      this.address_picked = true;
    },
    clearAddress() {
      this.addressLine1 = '';
      this.addressLine2 = '';
      this.addressLine3 = '';
      this.addressTown = '';
      this.addressCounty = '';
      this.addressCode = '';
      this.latitude = undefined;
      this.longitude = undefined;
      this.address_picked = false;
    },
    async submitEnquiry() {
      this.loading = true;

      const validationErrors = validate(
        {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
          notes: this.notes,
          addressLine1: this.addressLine1,
          addressLine2: this.addressLine2,
          addressLine3: this.addressLine3,
          addressTown: this.addressTown,
          postcode: this.addressCode,
          countryCode: this.addressIso,
          termsAndConditions: this.acceptTerms,
        },
        {
          firstName: {
            presence: { allowEmpty: false },
            length: {
              maximum: 32,
            },
          },
          lastName: {
            presence: { allowEmpty: false },
            length: {
              maximum: 32,
            },
          },
          phone: {
            presence: { allowEmpty: false },
            length: {
              maximum: 32,
            },
          },
          email: {
            presence: { allowEmpty: false },
            email: true,
            length: {
              maximum: 255,
            },
          },
          notes: {
            presence: { allowEmpty: true },
            length: {
              maximum: 255,
            },
          },
          postcode: {
            presence: { allowEmpty: false },
            length: {
              maximum: 16,
            },
          },
          countryCode: {
            presence: { allowEmpty: false },
            length: {
              maximum: 3,
            },
          },
          termsAndConditions: {
            inclusion: {
              within: [true],
              message: ' - You must check the box to continue.',
            },
          },
        },
      );

      if (validationErrors) {
        this.loading = false;
        this.alertBox().fire({
          title: 'We need some more information to save your request',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      await this.$store.dispatch('visualiser/saveToServer');

      this.saveQuote({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phone: this.phone,
        notes: this.notes,
        addressLine1: this.addressLine1,
        addressLine2: this.addressLine2,
        addressLine3: this.addressLine3,
        addressTown: this.addressTown,
        addressCounty: this.addressCounty,
        addressCode: this.addressCode,
        addressIso: this.addressIso,
        latitude: this.latitude,
        longitude: this.longitude,
      });
    },
  },
};
</script>
